var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<tr>\n    <td>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":15}}}) : helper)))
    + "</td>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isTemp") || (depth0 != null ? lookupProperty(depth0,"isTemp") : depth0)) != null ? helper : alias2),(options={"name":"isTemp","hash":{},"fn":container.noop,"inverse":container.program(2, data, 0),"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":6,"column":15}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isTemp")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helper = (helper = lookupProperty(helpers,"isTemp") || (depth0 != null ? lookupProperty(depth0,"isTemp") : depth0)) != null ? helper : alias2),(options={"name":"isTemp","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":14,"column":15}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isTemp")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <td colspan=\"2\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"translation") || (depth0 != null ? lookupProperty(depth0,"translation") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"translation","hash":{},"data":data,"loc":{"start":{"line":5,"column":24},"end":{"line":5,"column":39}}}) : helper)))
    + "</td>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"translation") || (depth0 != null ? lookupProperty(depth0,"translation") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"translation","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":27}}}) : helper)))
    + "</td>\n        <td>\n            <button class=\"w3-button w3-round w3-red remove-temp-key\" data-i18n-key=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":10,"column":85},"end":{"line":10,"column":92}}}) : helper)))
    + "\" >\n                <i class=\"fa fa-trash-o fa-fw\" data-i18n-key=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":11,"column":62},"end":{"line":11,"column":69}}}) : helper)))
    + "\"></i>\n            </button>\n        </td>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "<tr id=\"i18n-trads-table-alert\">\n    <td colspan=\"3\">Select a context first please</td>\n</tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"translations") || (depth0 != null ? lookupProperty(depth0,"translations") : depth0)) != null ? helper : alias2),(options={"name":"translations","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":16,"column":17}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"translations")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helper = (helper = lookupProperty(helpers,"translations") || (depth0 != null ? lookupProperty(depth0,"translations") : depth0)) != null ? helper : alias2),(options={"name":"translations","hash":{},"fn":container.noop,"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":17,"column":0},"end":{"line":21,"column":17}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"translations")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});