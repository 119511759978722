import App from '../index';
import Paginator from './paginator';
class Logs {

    desc = null;
    type = null;
    id = null;
    closeCallback = ()=>{};
    originIconMapping = {
        unknown: 'question',
        app: 'chrome',
        i18n: 'globe',
        open: 'unlock'
    }
    paginator = null;
    logs = [];
    filters = {};
    envs = null;
    langs = null;
    projectId = 0;

    constructor(projectId){
        this.paginator = new Paginator(15,'logs-paginator',
            this.showLogs.bind(this), 10, 'backend'
        );
        this.projectId = projectId
    }

    getLogsContainer(){
        return $('#logs-table > tbody');
    }

    decorateRecord( log ){
        log._logTime = new Date(log.logTime).toLocaleString();
        log._originIcon = log.origin !== '' ? this.originIconMapping[log.origin]:null;
        log._text = log.env+ ' / '+(log.lang ??'-')+' / '+(log.context ?? '-')+ ' / '+(log.key ?? '-');
        return log;
    }

    //rendering services

    showFilter(){
        const modal = App.getModal();
        const origins = Object.keys(this.originIconMapping).map(key => {
            return {key:key,desc:key};
        });
        modal.info(
            {
                header: App.viewRender('logs-filter-header',{}),
                content: App.viewRender('logs-filter',{envs: this.envs,origin: origins})
            }
        );
        Object.keys(this.filters).forEach( (key)=>{
            if (this.filters[key]){
                $('#logs-filter-form').find("[name='"+key+"']").val(this.filters[key]);
            }
        });
        $('#logs-filter-form').on('submit',this.filter.bind(this));
    }

    filter(event){
        event.preventDefault();
        const formData = new FormData(document.getElementById('logs-filter-form'));
        formData.forEach((value, key) => this.filters[key] = value);
        this.getCount().then(
            (count) => {
                this.paginator.setRecordsCount(count);
            }
        )
        App.getModal().close();
    }

    initPage(app,closeCallback){
        App.setPageHandler(this);
        this.desc = app+ " translation logs";
        this.closeCallback = ()=>{this.destroy();closeCallback()};
        App.renderPage();
    }

    getFilterParams( count ){
        let paramPrepared = []; 
        paramPrepared.push('projectId='+this.projectId);
        Object.keys(this.filters).forEach( 
            value => this.filters[value] && paramPrepared.push(value+'='+encodeURIComponent(this.filters[value]))
        );
        if (count){
            paramPrepared.push('count=1');
        }
        else {
            paramPrepared.push('offset='+this.paginator.getOffset());
            paramPrepared.push('limit='+this.paginator.getLimit());
        }
        return paramPrepared.join('&');
    }

    showLogs(){
        this.getLogsContainer().empty();
        if (this.paginator.recordCount > 0){
            this.getLogsContainer().html(App.viewRender('loading',{}));
            App.api.get('i18n/logs?'+this.getFilterParams(false), 
                (payload)=>{
                    this.logs = payload.map(
                        (log)=>{
                            return this.decorateRecord(log);
                        }
                    );
                    this.getLogsContainer().html(
                        App.viewRender('logs-table-content',{logs:this.paginator.getRecordsToShow(this.logs)})
                    );
                }
            );
        }
    }

    getEnvs(){
        this.envs=[];
        App.api.get('envs',
            (payload) => {
                Object.keys(payload).forEach( 
                    key=> {
                        this.envs.push({key:key,desc:payload[key]})
                    }
                );
            }
        );   
    }

    getCount(){
        return new Promise(
            (resolve,reject) =>{
                App.api.get('i18n/logs?'+this.getFilterParams(true),
                    (payload) => {
                        resolve(payload[0]);
                    }
                );
            }
        );
    }
    
    render(){
        this.getCount().then(
            (count) => {
                App.getMainContainer().html(App.viewRender('logs',{desc:this.desc}));
                $('#logs-back').on('click',this.closeCallback.bind(this));
                $('#logs-filter').on('click',this.showFilter.bind(this));
                this.paginator.setRecordsCount( count );
            }
        )
        if (this.envs == null){
            this.getEnvs();
        }
    }

    destroy(){
        App.getMainContainer().off().empty();
    }

}
export default Logs;