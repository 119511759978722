import Translations from "./translations";
import App from '../index';
class Menu {
  menus = [];
  projects = null;

  getOptions(){
    return new Promise(
      (resolve,reject)=>{
        App.api.get(
          'i18n/projects',
          ((payload)=>{
            this.projects = payload;
            payload.map(
              ((project)=>{
                this.menus.push(
                  {
                    id: 'menu-item-'+project.code,
                    icon: project.icon,
                    text: project.name,
                    code: project.code,
                    _id: project.projectID,
                    lang: project.lang,
                    mapping: Translations
                  }
                )
                resolve(true);
              }).bind(this)
            )
          }).bind(this),
          (error) =>{reject(error)}
        )
      }
    )


  }

  toggle(){
      let sidebar = $("#home-sidebar");
      let overlayBg  = $('#home-overlay');
      sidebar.toggle();
      overlayBg.toggle();
  }

  go( link, mayToggle = true ){
    if (this.menus.filter((element)=>element.code === link).length > 0){
      const menu = this.menus.filter((element)=>element.code === link)[0];
      App.getMainContainer().empty();
      const options = Object.assign({},menu);
      delete(options.mapping);
      delete(options.id);
      App.setPageHandler( new menu.mapping(options) );

      $('#menu-items > a').removeClass('w3-theme-d3');
      $('#'+(menu.id)).addClass('w3-theme-d3');
      App.renderPage();
      mayToggle && this.toggle();
      localStorage.setItem('lastMenu',link);
    }
    else {
      App.notifier.error("You don't have permissions to access this option.");
    }
  }

  render( initial = false, groups ){
    this.userGroups = groups;
    return new Promise( (resolve,reject)=> {
      this.getOptions().then(
        ()=>{
          $('#home-sidebar').html( App.viewRender('menu',{
            menus: this.menus
          }));
          this.menus.forEach((menu)=>{
            $('#'+menu.id).on('click', this.go.bind(this,menu.code));
          });
          App.getBurgerButton().on('click',this.toggle.bind(this));
          App.getOverlay().on('click',this.toggle.bind(this));
          if (initial){
            const storageMenu = localStorage.getItem('lastMenu');
            this.go(storageMenu ? storageMenu : this.menus[0].code,false);
          }
          resolve(true)
        }
      ).catch(
        (error)=> { reject(error)}
      );

    })


  
  }


}
export default Menu;