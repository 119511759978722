import App from '..';
class Modal {

    constructor(){
        this.selector = '#modal-container'
    }

    confirm( config ){
        if (typeof config.message == 'undefined'){
          config.message = 'No message';
        }
        if (typeof config.ok !== 'function'){
          config.ok = ()=>{};
        }
        this.destroy();
        $(this.selector).html(
          App.viewRender('modal',{
            footer:{
              showOk: true,
            },
            header: 'Confirmation is needed',
            content: config.message
          })
        );
        $('.modal-ok').on('click',()=>{config.ok(),$(this.selector).toggle();this.destroy()});
        $('.modal-close').on('click',()=>{$(this.selector).toggle();this.destroy()});
        $(this.selector).toggle();
    }

    info (config){
      this.destroy();
      $(this.selector).html(
        App.viewRender('modal',{
          footer: config.footer
        })
      );
      $('.w3-theme-modal-header').append(config.header)
      $('.w3-modal-content > div').html(config.content);
      $('.modal-close').on('click',()=>{
        this.close();
      });
      $('.modal-ok').on('click',()=>{

        if (typeof config.footer.okCallback == 'function'){
          config.footer.okCallback()
        }
        this.close();    
      });
      $(this.selector).toggle();
    }

    close(){
      $(this.selector).toggle();
      this.destroy();
    }

    destroy(){
      $(this.selector).off().empty();
    }

    /*
    info ( config ){
        if (typeof close !== 'function'){
            close = ()=>{};
        }
      
        const modal = new this.modalHandler.modal({
        footer: false,
        stickyFooter: false,
        closeMethods: ['escape','button','overlay'],
        onClose: function() {
          typeof config.close == 'function' && config.close();
          this.destroy();
        },
        beforeClose: () => true
        });
        modal.setContent(config.content);
      
        modal.open();       
    }
    */
}
export default Modal;