var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"w3-row\">\n    <div class=\"w3-half \" style=\"padding-right: 16px;\">\n        <div class=\"w3-dropdown-click w3-block\">\n            <div class=\"w3-block\">\n                <input type=\"text\" class=\"w3-input w3-block\" id=\"combo-multiselector-search-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" placeholder=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"placeholder") : depth0), depth0))
    + "\"/>\n            </div>\n            <div id=\"combo-multiselector-content-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"w3-dropdown-content w3-bar-block w3-border\" style=\"width:100%\">\n                Content pending\n            </div>\n        </div>\n    </div>\n    <div class=\"w3-half w3-round w3-border w3-padding w3-white\" id=\"combo-multiselector-tags-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("./combo-multiselector-content-tags.handlebars"),depth0,{"name":"combo-multiselector-content-tags","hash":{"placeholderTags":(depth0 != null ? lookupProperty(depth0,"placeholderTags") : depth0),"values":(depth0 != null ? lookupProperty(depth0,"valueList") : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <input type=\"hidden\" name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"/>\n</div>";
},"usePartial":true,"useData":true});