var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":18,"column":25},"end":{"line":18,"column":32}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"desc") || (depth0 != null ? lookupProperty(depth0,"desc") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"desc","hash":{},"data":data,"loc":{"start":{"line":18,"column":34},"end":{"line":18,"column":42}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<form id=\"logs-filter-form\" class=\"w3-theme-light w3-padding-16\">\n  <div class=\"w3-row w3-margin\">\n    <div class=\"w3-half w3-container w3-left-align\">\n      <label>Date from</label>\n      <input class=\"w3-input w3-border\" type=\"date\" min=\"2024-01-01\" placeholder=\"Date from\" name=\"from\">\n    </div>\n    <div class=\"w3-half w3-container w3-left-align\">\n      <label>Date to</label>\n      <input class=\"w3-input w3-border\" type=\"date\" min=\"2024-01-01\" placeholder=\"Date to\" name=\"to\">\n    </div>\n  </div>\n  <div class=\"w3-row w3-margin\">\n    <div class=\"w3-half w3-container w3-left-align\">\n      <label>Application origin</label>\n      <select name=\"origin\" class=\"w3-select\">\n        <option value=\"\" disabled selected>Select origin</option>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"origin") || (depth0 != null ? lookupProperty(depth0,"origin") : depth0)) != null ? helper : alias2),(options={"name":"origin","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":19,"column":19}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"origin")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "      </select>\n    </div>\n    <div class=\"w3-half w3-container w3-left-align\">\n      <label>IP origin</label>\n      <input class=\"w3-input w3-border\" type=\"text\" placeholder=\"Ip\" name=\"ip\">\n    </div>\n  </div>\n  <div class=\"w3-row w3-margin\">\n    <div class=\"w3-half w3-container w3-left-align\">\n      <label>User email</label>\n      <input class=\"w3-input w3-border\" type=\"email\" placeholder=\"User email\" name=\"user\">\n    </div>\n    <div class=\"w3-half w3-container w3-left-align\">\n      <label>Log text (like)</label>\n      <input class=\"w3-input w3-border\" type=\"text\" placeholder=\"Log text\" name=\"log\">\n    </div>\n  </div>\n  <div class=\"w3-row w3-margin\">\n    <div class=\"w3-half w3-container w3-left-align\">\n      <label>Environment</label>\n      <select name=\"env\" class=\"w3-select\">\n        <option value=\"\" disabled selected>Select environment</option>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"envs") || (depth0 != null ? lookupProperty(depth0,"envs") : depth0)) != null ? helper : alias2),(options={"name":"envs","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":44,"column":17}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"envs")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </select>\n    </div>\n    <div class=\"w3-half w3-container w3-left-align\">\n      <label>Languague</label>\n      <input class=\"w3-input w3-border\" type=\"text\" placeholder=\"Language\" name=\"lang\">\n    </div>\n  </div>\n  <div class=\"w3-row w3-margin\">\n    <div class=\"w3-half w3-container w3-left-align\">\n      <label>Context (like)</label>\n      <input class=\"w3-input w3-border\" type=\"search\" placeholder=\"Context\" name=\"context\">\n    </div>\n    <div class=\"w3-half w3-container w3-left-align\">\n      <label>Translation key (like)</label>\n      <input class=\"w3-input w3-border\" type=\"search\" placeholder=\"Key\" name=\"key\">\n    </div>\n  </div>\n  <div class=\"w3-row w3-margin\">\n    <div class=\"w3-container w3-block\">\n      <button type=\"submit\" class=\"w3-button w3-round w3-block w3-theme-action\">OK</button>\n    </div>\n  </div>\n\n</form>";
},"useData":true});