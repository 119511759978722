import Api from "./api";
import Modal from "./modal";
import Menu from "./menu";
import Notifier from "./notifier";


class App {

  constructor(){
    this.pageHandler = null;
    this.menu = new Menu();
    this.meGroups = [];
    const params = new URLSearchParams(document.location.search);
    const paramToken = params.get("t"); // is the string "Jonathan" 
    const refreshToken = params.get('r');
    const expires = params.get('e');
    this.api = new Api(this.renderLogin.bind(this) );
    if (paramToken){
      this.api.setToken(paramToken).setRefreshToken(refreshToken).setExpires(expires);
      document.location = '/';
    }
    else {
      this.modal = new Modal();
      this.notifier = new Notifier();
    }
  }

  getModal(){
    return this.modal;
  }

  copyClipboard(target,event){
    // Get the text field
    var copyText = document.getElementById(target);
    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    if (copyText.value !== ''){
        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);

        // Alert the copied text
        this.notifier.success("Correctly copied in the clipboard");
    }
    else {
        this.notifier.warning('Text to copy is empty.');
    }
  }

  viewRender(templateName,payload){
    const compiled = require('../static/templates/'+templateName+'.handlebars');
    return compiled(payload);
  }

  getMainContainer(){
    return $('#app-container');
  }

  getTopBar(){
    return $('#top-bar');
  }

  getBurgerButton(){
    return $('#burger-button');
  }

  getOverlay(){
    return $('#home-overlay');
  }

  setPageHandler( handler ){
    this.pageHandler = handler;
  }

  getPageHandler(){
    return this.pageHandler;
  }

  showLoading(){
    this.getMainContainer().find('*').off();
    this.getMainContainer().empty();
    this.getMainContainer().html(this.viewRender('loading',{}));
  }

  showForbidden(){
    this.getMainContainer().find('*').off();
    this.getMainContainer().empty();
    this.getMainContainer().html(this.viewRender('forbidden',{})); 
  }

  setSpinApi(selector){
    let previousValue = '';
    this.api.prefetchCallback = (
        () => {
          previousValue = typeof selector ==  'string' ? $('#'+selector).html() : $(selector).html();
          this.addButtonSpin(selector)
      }
    ).bind(this,selector,previousValue);
    this.api.postfetchCallback = (
      () => {
       (typeof selector ==  'string' ? $('#'+selector) : $(selector)).html(previousValue);
      }
    ).bind(this,selector,previousValue)
  }

  resetSpinApi( execPostCallback ){
    execPostCallback && this.api.postfetchCallback();
    this.api.prefetchCallback = this.api.postfetchCallback = null;
  }

  addButtonSpin(id){
    let sel = '';
    if (typeof id == 'string'){
      sel = $('#'+id)
    }
    else {
      sel = $(id)
    }
    sel.html('<i class="w3-content fa fa-refresh w3-spin w3-margin-right"></i>'+sel.html())
  }

  renderPage(){
    this.showLoading();
    this.pageHandler.render();
  }

  signInSSO(event){
    this.addButtonSpin($(event.currentTarget));
    this.api.ssoLogin();
  }

  

  signOut(){
    this.api.logout();
  }

  isMemberOf(groups){
    return this.meGroups.filter(value => groups.includes(value)).length > 0;
  }

  renderHome(){
    this.api.get('me',(
      (result)=> {
        $('body').html( this.viewRender('home',{}));
        this.meGroups = result._groups;
        this.notifier.render();
        this.menu.render( true, this.meGroups ).then(
          (()=>{
            $( "#user-name" ).html( result.firstName );
            $( '#home-signout' ).on('click',this.signOut.bind(this));
            this.api.get('me/photo',
              (buffer)=> {
                const blob = new Blob([buffer], {type: "image/jpg"});
                const blobUrl = URL.createObjectURL(blob);
                $('#user-avatar').attr("src", blobUrl);
              },
              null,
              'buffer'
            );
          }).bind(this)
        ).catch(
          (error)=> {
            this.notifier.error(error);
          }
        )

      }
    ));
  }

  renderLogin(){
    $('*').off();
    $('body').html( this.viewRender('login',{}));
    $('#login-microsoft-button').on('click',this.signInSSO.bind(this));
  }

  render(){
    $((()=>{ 
      if (this.api.hasToken()){
        this.renderHome();
      }
      else {
        this.renderLogin();
      }
    }).bind(this));
  }
}
export default App;
