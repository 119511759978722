var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header class=\"w3-container\" style=\"padding-top:0px\">\n    <div class=\"w3-row\">\n        <div class=\"w3-threequarter\">\n    <h4><i class=\"fa fa-file-text w3-margin-right\"></i> "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"desc") || (depth0 != null ? lookupProperty(depth0,"desc") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"desc","hash":{},"data":data,"loc":{"start":{"line":4,"column":56},"end":{"line":4,"column":64}}}) : helper)))
    + "</h4>\n        </div>\n        <div class=\"w3-quarter w3-right-align w3-padding\">\n            <button class=\"w3-button w3-round w3-theme-secondary-action w3-large w3-margin-left\" id=\"logs-back\"> <i class=\"fa fa-chevron-left\" aria-hidden=\"true\"></i> </button>\n        </div>\n    </div>\n</header>\n<div class=\"w3-panel w3-theme-light w3-padding-16 w3-round w3-margin\">\n    <button id=\"logs-filter\" class=\"w3-button w3-small w3-right w3-border w3-round w3-padding w3-margin-bottom\">\n        <i class=\"fa fa-sliders w3-margin-right\" aria-hidden=\"true\"></i> Filter\n    </button>\n    <table class=\"w3-table-all w3-margin-top\" id=\"logs-table\">\n        <thead>\n            <tr>\n                <th style=\"\">Time</th>\n                <th style=\"\">Origin</th>\n                <th style=\"\">Ip</th>\n                <th style=\"\">User</th>\n                <th style=\"\">Env / Lang / Context / Key</th>\n                <th style=\"\">Log</th>\n            </tr>\n        </thead>\n        <tbody class=\"w3-center\">\n        </tbody>\n    </table>\n    <div class=\"w3-center w3-margin-top\" id=\"logs-paginator\">\n    </div>\n</div>\n";
},"useData":true});