var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "    <tr>\n        <td >"
    + alias4(((helper = (helper = lookupProperty(helpers,"_logTime") || (depth0 != null ? lookupProperty(depth0,"_logTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_logTime","hash":{},"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":25}}}) : helper)))
    + "</td>\n        <td class=\"w3-center\">\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"_originIcon") || (depth0 != null ? lookupProperty(depth0,"_originIcon") : depth0)) != null ? helper : alias2),(options={"name":"_originIcon","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":7,"column":28}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"_originIcon")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </td>\n        <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"ip") || (depth0 != null ? lookupProperty(depth0,"ip") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ip","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":18}}}) : helper)))
    + "</td>\n        <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"user") || (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":20}}}) : helper)))
    + "</td>\n        <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"_text") || (depth0 != null ? lookupProperty(depth0,"_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_text","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":21}}}) : helper)))
    + "</td>\n        <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"comments") || (depth0 != null ? lookupProperty(depth0,"comments") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"comments","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":24}}}) : helper)))
    + "</td>\n    </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"fa fa-"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\"></i>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  stack1 = ((helper = (helper = lookupProperty(helpers,"logs") || (depth0 != null ? lookupProperty(depth0,"logs") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"logs","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":14,"column":9}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"logs")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { return stack1; }
  else { return ''; }
},"useData":true});