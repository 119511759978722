import App from '../index';

class Paginator {
    constructor(limit,containerId,callback,maxShownPages,type){
        this.offset = 0;
        this.limit = limit >0 ? limit : 1;
        this.containerId = containerId;
        this.recordCount = 0;
        this.selected = 1;
        this.pages = 1;
        this.callback = callback;
        this.maxShownPages = (maxShownPages ?? 10);
        this.type = (type ?? 'memory');
    }

    setRecordsCount( recordCount ){
        this.offset = 0;
        this.selected = 1;
        this.recordCount = recordCount;
        this.pages = Math.ceil(this.recordCount / this.limit );
        this.pages = this.pages <= 0 ? 1:this.pages;
        this.render();
        console.log(this);
    }

    getRecordsToShow( ds ){
        let records = null;
        if (this.type == 'memory'){
            records =  ds.slice(this.offset,this.offset+this.limit);
        }
        else {
            records = ds;
        }
        return records;
    }

    setSelected( index) {
        console.log(this);
        this.selected = index;
        this.offset = (index-1) * this.limit;
        this.render();
    }

    calculatePages(){
        let start = this.selected - (Math.floor(this.maxShownPages/2));
        let end = this.selected + (Math.ceil(this.maxShownPages/2));
        const pageList = [];

        if (start < 1) {
            end+= 1+Math.abs(start);   
            if (end >= this.pages){
                end = this.pages;
            }
            start = 1;
        }
        if (end > this.pages) {
            start-=end-this.pages;
            if (start <1){
                start = 1;
            }
            end = this.pages;
        }
        if (start == 2){
            start = 1;
        }
        if (end == this.pages-1){
            end = this.pages;
        }
        if (start>1){
            pageList.push({number:1,selected:false});
            pageList.push({number:'...',selected:false,noPage:true});
        }
        for(let i=start;i<=end;i++){
            pageList.push({number:i,selected:this.selected == i})
        }
        if (end < this.pages){
            pageList.push({number:'...',selected:false,noPage:true});
            pageList.push({number:this.pages,selected:false})
        }
        return pageList;

    }

    getOffset(){
        return this.offset;
    }

    getLimit(){
        return this.limit;
    }

    render(){

        const pageList = this.calculatePages();
        
        $('#'+this.containerId).html(
            App.viewRender('paginator',{
                pages: pageList,
                records: this.recordCount,
                showing: this.recordCount>0 ? (
                    this.selected == this.pages? this.recordCount % this.limit: this.limit
                ) : 0
            })
        );
        if (this.selected > 1){
            $('#'+this.containerId+' > div > a:first-child').on('click',this.setSelected.bind(this,this.selected-1));
        }
        if (this.selected <  this.pages){
            $('#'+this.containerId+' > div > a:last-child').on('click',this.setSelected.bind(this,this.selected+1));
        }
        $('#'+this.containerId+' > div').children('.paginator-page').each(
            (key,value) => {
                $(value).on('click',this.setSelected.bind(this,Number(value.innerHTML)));
            }
        );
        this.callback();
    }

}
export default Paginator;