var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div id=\"i18n-token\" class=\"w3-panel w3-theme-light w3-padding-16 w3-round w3-margin\">\n    <h5>Application token generator</h5>\n      <div class=\"w3-row-padding\">\n          <div class=\"w3-rest\">\n              <select id=\"i18n-token-environment\" class=\"w3-select\" name=\"option\">\n                  <option value=\"\" disabled selected>Choose the environment</option>\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"envs") : depth0), depth0),{"name":"envs","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":18},"end":{"line":22,"column":27}}})) != null ? stack1 : "")
    + "              </select>\n          </div>\n      </div>\n      <div class=\"w3-row-padding w3-margin-top\">\n          <div class=\"w3-twothird\">\n              <input id=\"i18n-token-placeholder\" class=\"w3-input w3-border-0 w3-margin-right w3-disabled\" type=\"text\">\n          </div>\n          <div class=\"w3-col\" style=\"width:100px\">\n              <button class=\"w3-button w3-round w3-theme-secondary-action\" id=\"i18n-token-copy\"><i class=\"fa fa-clone fa-fw\"></i></button>\n          </div>\n          <div class=\"w3-rest\">\n              <button class=\"w3-button w3-round w3-theme-action w3-block\" id=\"i18n-token-generator\">Generate</button>\n          </div>\n      </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"desc") : depth0), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n  <header class=\"w3-container\" style=\"padding-top:0px\">\n    <div class=\"w3-row\">\n        <div class=\"w3-threequarter\">\n          <h4><i class=\"fa w3-margin-right fa-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"icon") : stack1), depth0))
    + "\"></i> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + " management</h4>\n        </div>\n        <div class=\"w3-quarter w3-right-align w3-padding\">\n            <button class=\"w3-button w3-round w3-theme-secondary-action w3-large\" id=\"i18n-logs\"> <i class=\"fa fa-file-text\"></i> </button>\n        </div>\n    </div>\n  </header>  \n\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"apiPermission") : depth0), depth0),{"name":"apiPermission","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":2},"end":{"line":38,"column":20}}})) != null ? stack1 : "")
    + "  <div id=\"i18n-trads\" class=\"w3-panel w3-theme-light w3-padding-16 w3-round w3-margin\">\n"
    + ((stack1 = container.invokePartial(require("./i18n-trads.handlebars"),depth0,{"name":"i18n-trads","hash":{"envs":(depth0 != null ? lookupProperty(depth0,"envs") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n";
},"usePartial":true,"useData":true});