var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a  id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":22}}}) : helper)))
    + "\" class=\"w3-bar-item w3-button w3-round w3-padding\"><i class=\"fa fa-"
    + alias4(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":17,"column":90},"end":{"line":17,"column":98}}}) : helper)))
    + " fa-fw\"></i>  "
    + alias4(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":17,"column":112},"end":{"line":17,"column":120}}}) : helper)))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "  <br>\n  <div class=\"w3-container w3-row\">\n    <div class=\"w3-col s4\">\n      <img id=\"user-avatar\" src=\"/images/avatar.png\" class=\"w3-circle w3-margin-right\" style=\"width:46px\">\n    </div>\n    <div class=\"w3-col s8 w3-bar\">\n      <span>Welcome, <strong id=\"user-name\">...</strong></span><br>\n      <a  class=\"w3-button w3-round w3-padding\" id=\"home-signout\"><i class=\"fa fa-sign-out fa-fw\"></i>  Sign-out</a>\n    </div>\n  </div>\n  <hr>\n  <div class=\"w3-container\">\n    <h5>Nextlane trnsltor</h5>\n  </div>\n  <div class=\"w3-bar-block\" id=\"menu-items\">\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"menus") || (depth0 != null ? lookupProperty(depth0,"menus") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"menus","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":18,"column":14}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"menus")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>";
},"useData":true});