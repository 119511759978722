import App from '../index';
class Notifier {

    show(className,text,timeout){
        $('#notifier-text').html( text );
        $('#notifier-container').addClass(className);
        $('#notifier-container').removeClass('w3-hide');
        if (timeout > 0){
            window.setTimeout(this.close, timeout*1000)
        }
    }
      
    warning(text){
        this.show('w3-theme-warn',text,6);
    }
      
    error(text){
        this.show('w3-theme-error',text,0);
    }
    
    success(text){
        this.show('w3-theme-ok',text,3);
    }
      
    close(){
        $('#notifier-container').addClass('w3-hide');
        $('#notifier-container').removeClass(['w3-theme-warn','w3-theme-ok','w3-theme-error']);
        $('#notifier-text').empty();
    }

    render(){
        App.getTopBar().append(App.viewRender('notifier',{}));
        $('#notifier-close').on('click',this.close.bind(this));
    }

}
export default Notifier;