import Paginator from './paginator';
import App from '../index';
import Logs from './logs';
class Translations {
    constructor( options ){
        this.paginator = new Paginator(10,'i18n-trads-table-paginator',this.renderTranslations.bind(this),4);
        this.loadedData = {};
        this.filteredData = {};
        this.envs = [];
        this.options = options;
        this.projectId = options._id;
        this.lang = options.lang;
        this.logs = new Logs(this.projectId);
        this.contexts = null;
    }

    //Backend related functions
    generateToken() {
        const env = $('#i18n-token-environment').val();
        if (env){
            App.setSpinApi('i18n-token-generator');
            App.api.get('i18n/'+env+'/token?projectId='+this.projectId,
                (payload)=> {
                    $('#i18n-token-placeholder').val(payload.token);
                }
            )
        }   
        else {
            App.notifier.warning('Please select a valid environment first');
        }     
    }


    removeTempKey(event){
        event.preventDefault();
        console.log(event.target);
        const key = $(event.target).attr('data-i18n-key');
        if (key.indexOf('***')!==0){
            App.notifier.error('Cannot remove not temporary keys');
            return;
        }
        App.api.delete('i18n/'+$('#i18n-trads-environment').val()+'/'+this.lang+'/'+$('#i18n-trads-context').val()+'/'+key.substring(3)+'?projectId='+this.projectId,
            (payload)=>{
                App.notifier.success('Key removed correctly');
                this.fetchTranslations();
            },
            (error)=>{
                App.notifier.error(error)
            }
        );
    }


    save(event){
        event.preventDefault();
        if ($('#i18n-trads-new-form').valid()){

            const data = new FormData(document.getElementById('i18n-trads-new-form'));
            const entries = Object.fromEntries(data.entries());
            const bodyPost = {
                context: entries["i18n-trads-new-context"],
                key: entries["i18n-trads-new-key"],
                tag: entries["i18n-trads-new-tag"],
                trans: entries["i18n-trads-new-translation"]
            };
            App.setSpinApi('i18n-trads-new-form :submit');
            App.api.post('i18n/'+$('#i18n-trads-environment').val()+'/'+this.lang+'/key?projectId='+this.projectId,
                (payload)=>{
                    App.notifier.success('Key created correctly');
                    if ($("#i18n-trads-context option[value='"+entries["i18n-trads-new-context"]+"']").length == 0){
                        $('#i18n-trads-context').append( 
                            new Option(entries["i18n-trads-new-context"],entries["i18n-trads-new-context"])
                        );                      
                    }
                    $('#i18n-trads-context').val(entries["i18n-trads-new-context"] );
                    this.fetchTranslations();
                },
                null,
                bodyPost
            );
        }
    }

    fetchTranslations( ){
        const env =  $('#i18n-trads-environment').val();
        let context =  $('#i18n-trads-context').val();
        $('#i18n-trads-new-context').val(context);
        Promise.all(
            [
                (() => { 
                    return new Promise(
                        (resolve,reject)=>{
                            App.api.get('i18n/'+env+'/'+this.lang+'/'+context+'/'+this.contexts[context]+'?projectId='+this.projectId,
                                (payload) => {
                                    resolve(payload);
                                }
                            );
                        }
                    )
                })(),
                (() => { 
                    return new Promise(
                        (resolve,reject)=>{
                            App.api.get('i18n/'+env+'/'+this.lang+'/'+context+'/temp?projectId='+this.projectId,
                                (payload) => {
                                    console.log(payload);
                                    Object.keys(payload).forEach( (key) =>{
                                        payload['***'+key] = payload[key];
                                        delete payload[key];
                                    })
                                    resolve(payload)
                                }
                            )
                        }
                    );
                })()
            ]
        ).then ( values =>{
            this.loadedData = {};
            console.log(values[1]);
            this.plainTranslations(values[0]);
            this.plainTranslations(values[1]);
            this.filteredData = this.loadedData;
            this.paginator.setRecordsCount(Object.keys(this.filteredData).length);
        });

    }

    //Helpers functions

    addValidation(){
        $('#i18n-trads-new-form').validate({
            "rules": {
                "i18n-trads-new-context": {
                "required": true,
                "minlength": 5
                },
                "i18n-trads-new-translation": {
                "required": true
                },
                "i18n-trads-new-key": {
                "required": true,
                "minlength": 5
                },
                "i18n-trads-new-tag": {
                "required": true,
                "minlength": 5
                }
            },
            "messages": {
                "i18n-trads-new-context": {
                "required": "Mandatory field.",
                "minlength": "Min length: 5 chars."
                },
                "i18n-trads-new-translation": {
                    "required": "Mandatory field.",
                },
                "i18n-trads-new-key": {
                    "required": "Mandatory field.",
                    "minlength": "Min length: 5 chars."
                },
                "i18n-trads-new-tag": {
                    "required": "Mandatory field.",
                    "minlength": "Min length: 5 chars."
                }
            },
            "errorPlacement": function(error, element) {
                element.addClass("w3-border-red");
                error.insertAfter(element);
            },
            "success": function(label) {
                label.removeClass("w3-border-red");
            }
        });
    }

    plainTranslations(list, preffix){
        Object.keys(list).forEach(key => {
            if (typeof list[key] === 'object'){
                this.plainTranslations(list[key],(preffix ?? '')+key+'::');
            }
            else {
                this.loadedData[(preffix ?? '')+key] = list[key];
            }
        });
    }

    hydrateContexts(){
        this.loadedData = {};
        this.filteredData = {};
        this.paginator.setRecordsCount(0);
        $('#i18n-trads-context').val('');
        $('#i18n-trads-new').removeClass('w3-hide');
        $('#i18n-trads-new-context').val(null);
        $('#i18n-trads-context > option').not(':first').remove();
        const env =  $('#i18n-trads-environment').val();
        App.api.get('i18n/'+env+'/'+this.lang+'/hash/list?projectId='+this.projectId,
            (payload)=> {
                this.contexts = payload;

                Object.keys(payload).forEach( 
                    key => {
                        $('#i18n-trads-context').append( new Option(key,key));
                    }
                );
            }
        );
    }

    //Rendering and initialization function
    filter(){
        const searchText = $('#i18n-trads-search').val()
        const keysCandidates = Object.keys(this.loadedData).filter((item)=>{
            return item.indexOf(searchText) >= 0 || this.loadedData[item].indexOf(searchText) >= 0;
        })
        this.filteredData = {};
        keysCandidates.forEach( key => {
            this.filteredData[key] = this.loadedData[key];
        });
        this.paginator.setRecordsCount(Object.keys(this.filteredData).length);
    }

    renderTranslations(){
        const listTrads = this.filteredData;
        const translations = [];
        this.paginator.getRecordsToShow(Object.keys(listTrads)).forEach( key => {
            translations.push({translation:listTrads[key],key:key,isTemp:key.indexOf('***')===0})
        });
        $('#i18n-trads-table > tbody').html(App.viewRender('i18n-translations',{translations:translations}));
        $('.remove-temp-key').on('click', this.removeTempKey.bind(this))
        
    }

    initToken() {
        $('#i18n-token-generator').on('click',this.generateToken.bind(this));
        $('#i18n-token-copy').on('click', App.copyClipboard.bind(App,'i18n-token-placeholder'));
    }

    initTranslations() {
        this.paginator.render();
        $('#i18n-trads-search').on('keyup',this.filter.bind(this));
        $('#i18n-trads-environment').on('change',this.hydrateContexts.bind(this));
        $('#i18n-trads-context').on('change',this.fetchTranslations.bind(this));
        $('#i18n-trads-new-form').on('submit',this.save.bind(this));
        $('#i18n-trads-refresh').on('click',this.fetchTranslations.bind(this))
        this.addValidation();
        $('#i18n-logs').on('click',(
            (event)=>{
                App.setSpinApi(event.currentTarget.id);
                this.logs.initPage( this.options.text, ( 
                    ()=>{
                        this.render();
                        this.logs = new Logs(this.projectId);
                    }
                ).bind(this)
                );
            }
        ).bind(this));
    }

    loadEnvs(){
        return new Promise(
            (resolve, reject) => {
                App.api.get('envs',
                (payload) => {
                    Object.keys(payload).forEach( 
                        key=> {
                            this.envs.push({key:key,desc:payload[key]})
                        }
                    );
                    resolve(true);
                }
            );               
            }
        )
    }

    render() {
        if (this.envs.length == 0){
            this.loadEnvs().then(
                () =>{
                    App.getMainContainer().html(
                        App.viewRender('i18n-main',{options:this.options, envs:this.envs,apiPermission:App.isMemberOf(['root'])})
                    );
                    this.initToken();
                    this.initTranslations();
                }
            )
        }
        else {
            App.getMainContainer().html(
                App.viewRender('i18n-main',{options:this.options, envs:this.envs,apiPermission:App.isMemberOf(['root'])})
            );
            this.initToken();
            this.initTranslations();
        }
    }
}
export default Translations;