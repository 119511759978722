
import 'w3-css/w3.css';
import './index.css'
import jQuery  from "jquery";
import validate from 'jquery-validation';
import AppClass from './js/app';

jQuery.validator.addMethod("nopastdate", (value,element,params)=>{
    console.log('nopastdatetime',value,element,params,this);
    const timeVal = $('#'+params.timeId).val();
    if (!timeVal) return true;
    const date = new Date(value +' '+timeVal);
    console.log(date.toLocaleString(),new Date().toLocaleString());
    return (new Date()).getTime() < date.getTime();
},"No dates in the past are allowed.");
window.$ = jQuery;
const App = new AppClass();
App.render();
window.App = App;
export default App;
