import App from '../index';
import process from 'process';

class UnauthorizedError extends Error{
    constructor (message = ""){
        super(message);
        this.name = 'UnauthorizedError';
        this.message = message;
    }
}

class ForbiddenError extends Error{
    constructor (message = ""){
        super(message);
        this.name = 'ForbiddenError';
        this.message = message;
    }
}

class Api {
    
    apiroot = '/api/';
    token = null;

    unathorizedCallback = null;

    prefetchCallback = null;
    postfetchCallback = null;
    refreshTokenPromise = null;

    constructor(unathorizedCallback ){
        this.unathorizedCallback = unathorizedCallback;
        this.token = localStorage.getItem('t');
        this.refreshToken = localStorage.getItem('r');
        this.expires = localStorage.getItem('e');
        this.backendUrl = process.env.BACKEND_URL ?? '';
        this.apiroot = this.backendUrl+this.apiroot;
        this.refreshTokenPromise = null;
    }

    setToken( t ){
        this.token = t;
        localStorage.setItem('t',t);
        return this;
    }

    setRefreshToken( r) {
        this.refreshToken = r;
        localStorage.setItem('r',r);
        return this;
    }

    setExpires( e ){
        this.expires = (new Date(e)).getTime();
        localStorage.setItem('e',this.expires);
        return this;
    }

    removeCredentials(){
        this.token = this.refreshToken = this.expires =  null;
        ['t','e','r'].forEach ( (key)=>localStorage.removeItem(key));
    }

    hasToken(){
        return this.token && this.refreshToken;
    }


    validToken(){
        return this.token && this.refreshToken && this.expires &&  (new Date()).getTime() < this.expires;
    }

    async getRefreshToken() {
        const response = await fetch(this.apiroot+'token/refresh',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+this.refreshToken
            },
            body: JSON.stringify({})
        });
        if (response.status == 401){
            this.removeCredentials();
            this.unathorizedCallback();
            throw new Error('Error refreshing token '+err.message);
        }
        const payload = await response.json();
        if (!response.ok){           
            throw new Error(
                (typeof payload.description !== 'undefined' && payload.description ) ? 
                    payload.description :  
                    'See console for further details'
            );
        }
        else {
            console.log('New token received');
            this.setToken( payload.token )
                .setRefreshToken (payload.refreshToken)
                .setExpires(payload.expires);
        }

    }

    async _call(url, type, method, okCB, koCB, body, notifyError = true){
        let mimeType = 'application/json';
        if (type === 'text'){
            mimeType = 'text/plain';
        }
        let params = {
            method: method,
            headers: {
                'Accept': mimeType,
                'Content-Type': 'application/json',
                'X-App-Origin' : 1
            }
        }
        if (type == 'buffer'){
            delete params.headers.Accept;
            delete params.headers['Content-Type'];
        }
        if (method !== 'get' && body !== null) {
            params.body = JSON.stringify(body)
        }
        try {
            if (this.token && !this.validToken()){
                if (!this.refreshTokenPromise){
                    this.refreshTokenPromise = this.getRefreshToken().then(
                        () => {
                            this.refreshTokenPromise = null;
                            return null;
                        }
                    )
                }
                await this.refreshTokenPromise;
            }
            if (this.token){
                params.headers.Authorization = 'Bearer '+this.token;
            }
            typeof this.prefetchCallback == 'function' && this.prefetchCallback();
            this.prefetchCallback = null;
            fetch(url,params)
                .then( async (response) => {
                    if (response.status == 401){
                        throw new UnauthorizedError('Unauthorized');
                    }
                    if (response.status == 403){
                        throw new ForbiddenError('Forbidden');
                    }
                    typeof this.postfetchCallback == 'function' && this.postfetchCallback();
                    this.postfetchCallback = null;
                    if(!response.ok) {
                        const payload = type == 'json' ? await response.json() : type=='buffer'? await response.arrayBuffer() : await response.text();
                        console.log('Error server response: ',payload);
                        throw new Error(
                            (type == 'json' && typeof payload.description !== 'undefined' && payload.description ) ? 
                                payload.description :  
                                'See console for further details'
                        );
                    }
                    return type == 'json' ? response.json() : type =='buffer' ? response.arrayBuffer(): response.text();
                })
                .then((payload)=>{
                    
                    okCB(payload);
                })
                .catch( (err) => {
                    if (err.name == 'UnauthorizedError' && typeof this.unathorizedCallback == 'function'){
                        this.removeCredentials();
                        this.unathorizedCallback();
                    }
                    else if (err.name == 'ForbiddenError'){
                        App.showForbidden();
                    }
                    else {
                        console.error(err);
                        if (notifyError){
                            App.notifier.error('Error found: '+ err.message);
                        }
                        typeof this.postfetchCallback == 'function' && this.postfetchCallback();
                        this.postfetchCallback = null;
                        typeof koCB === 'function' && koCB(err);
                    }
                })
            }
        catch( err ) {
            console.log(err);
        }
    }

    get(service,okCB,koCB = null, type = 'json'){
        this._call(this.apiroot+service,type,'get',okCB,koCB,null);
    }

    put(service,okCB,koCB, body){
        this._call(this.apiroot+service,'json','put',okCB,koCB,body);
    }

    post(service,okCB,koCB, body){
        this._call(this.apiroot+service,'json','post',okCB,koCB,body);
    }

    delete(service,okCB,koCB){
        this._call(this.apiroot+service,'json','delete',okCB,koCB,null);
    }

    logout(){
        window.location.href= this.backendUrl+'/logout?r='+this.refreshToken;
        this.removeCredentials();
    }

    ssoLogin(){
        document.location = this.backendUrl+'/ssoLogin';
    }

}
export default Api;